<template>
    <!-- 账号资料 移动端 -->
    <div class="center">
        <div class="row">
            <div class="left">
                {{$t('user.userName')}}
            </div>
            <div class="right">
                {{account}}
            </div>
        </div>
        <div class="row" @click="jump">
            <div class="left">
                {{$t('user.lv')}}
            </div>
            <div class="right jump">
                {{
                type==1?
                $t('level')[0]:
                type==2?
                $t('level')[1]:
                type==4?
                $t('level')[2]:
                type=='DEX'?
                $t('level')[3]:type
                }}
                <i class="iconfont2 icon-right_arrow"></i>
            </div>
        </div>
        <!-- 自动续费 -->
        <div class="row">
            <div class="left">
                {{$t('user.autoRenew')}}
            </div>
            <div class="right">
                <el-switch @change="autoRenewChange" active-value="1" inactive-value="0" v-model="autoState"
                    active-color="#E65B1B" inactive-color="#e9e9e9">
                </el-switch>
            </div>
        </div>
        <div class="row">
            <div class="left">
                {{$t('user.help')}}
            </div>
            <div class="right">
                <el-switch active-value="1" inactive-value="0" v-model="helpState" active-color="#E65B1B"
                    inactive-color="#e9e9e9">
                </el-switch>
            </div>
        </div>
    </div>
</template>
<style lang="stylus" scoped>
    @import 'center.styl';
</style>
<script>
    import { Loading } from 'element-ui';

    import { mapGetters } from 'vuex';
    // api
    import { paySet } from '@/api/user'

    export default {
        data() {
            return {
                autoState: '1',
            }
        },
        created() {
            this.autoState = this.pay_set
            if (this.isMobile == 1) {
                const loading = Loading.service({
                    target: '.main',
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'apploading',
                });
                this.getInfo();
                loading.close();
            } else {
                this.$router.push({
                    path: '/user'
                })
            }
        },
        computed: {
            ...mapGetters(['account', 'type', 'isMobile', 'pay_set', 'novice']),
            helpState: {//是否需要新手引导
                get() {
                    return this.novice;
                },
                set(value) {
                    // 当switch的值变化时，调用action来更新store中的novice
                    this.$store.dispatch('user/setNovice', value)
                }
            }
        },
        methods: {
            jump() {
                this.$router.push({
                    path: '/renew',
                })
                // this.$message({
                //     message: this.$t('tip.renewTip'),
                //     type: 'info',
                //     center: true,
                //     customClass: 'shotMsg',
                // });
            },
            // 获取详情
            getInfo() {
                const loading = Loading.service({
                    target: '.main',
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'apploading',
                });
                this.$store.dispatch('user/getInfo').then(res => {
                    this.autoState = this.pay_set
                    loading.close();
                }).catch(err => {
                    loading.close();
                })
            },
            // 自动续费开关
            autoRenewChange() {
                paySet({
                    account: this.account,
                    type: this.autoState,
                }).then(res => {
                    this.getInfo()
                    // 修改成功
                    this.$message({
                        message: this.$t('tip.operationSuc'),
                        type: 'success',
                        center: true,
                        customClass: 'shotMsg',
                    });
                }).catch(err => {
                    this.autoState = this.autoState == '1' ? '0' : '1'
                })
            },
        }
    }
</script>